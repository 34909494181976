@media screen and (min-width: 1750px) {

    .her-title {
        font-size: 85px;
    }

    .desc {
        font-size: 28px;
    }

    .custom-btn_ftm {
        font-size: 24px;
        padding: 25px 60px;
    }

    .max-60 {
        max-width: 100%;
    }

    label {
        font-size: 18px;
        line-height: 1;
    }

    input.form-control {
        font-size: 17px;
    }
}

@media screen and (min-width: 1600px) {
    #form-sec {
        margin-top: -18%;
    }

    .max-60 {
        max-width: 700px;
    }

    #started-sec .started-card {
        padding: 100px;
    }

    .started-card .desc {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .form-card {
        padding: 100px 170px;
    }
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1300px) {

    .her-title {
        font-size: 60px;
    }

    /* .title {
        font-size: 45px;
    } */

    .card {
        padding: 20px;
        padding-bottom: 0;
        min-height: 400px;
    }

    .card-title {
        font-size: 20px;
    }

    p.card-desc {
        font-size: 15px;
    }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1200px) {
    .form-card .max-60::after {
        bottom: -205%;
    }


    #slider::before {
        right: 4%;
    }

    .form-card .max-60::before {
        bottom: -230%;
    }

    .her-title {
        font-size: 50px;
    }

    .desc {
        font-size: 18px;
    }

    .form-card {
        padding: 80px 40px;

    }

    /* .title {
        font-size: 40px;
    } */

    .ftm_container label {
        font-size: 14px;

    }

    .sm-title {
        font-size: 14px;
    }

    .card {
        padding-bottom: 0;
        border-radius: 20px;
        padding: 10px;
        min-height: 370px;

    }

    .ftm_container p.card-desc {
        font-size: 16px;

    }

    .card-title {
        font-size: 16px;
    }

    /* .girl-sm {
        width: 170px;
    } */

    .login-card_ftm .custom-btn_ftm {
        max-width: 100%;

    }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {

    .custom-btn_ftm, .homeFBIner_ftm button {
        font-size: 16px;
        padding: 15px 30px;
    }
    #login-sec_ftm::after {
      width: 87%;
    }
     .border-righ {
        margin-right: 0;
       
    } 

    /* .border-righ::after {
        display: none;
    } */

    .her-title {
        font-size: 55px;
    }

    #banner::before {
        display: none;
    }

    .arrow::after {
        width: 85px;
        height: 104px;
        right: -50%;
    }

    #form-sec {
        margin-top: -30%;
    }

    .form-card {
        padding: 60px 50px 100px;
    }

    /* .title {
        font-size: 38px;
    } */

    .ftm_container label {
        min-height: auto;
    }

    .mt-60 {
        margin-top: 40px;
    }

    #started-sec {
        margin: 70px 0;
    }

    #started-sec .started-card {
        padding: 30px;
    }

    #started-sec .calculator-img {
        width: 50px;
    }

    #started-sec .title {
        font-size: 30px;
    }

    #started-sec .desc {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .timeline-sec {
        padding: 50px 30px 50px;
    }

    .timeline-title {
        font-size: 25px;
    }

    .child-icon1::before {
        top: -33px;
        /* left: 30%; */
        width: 70px;
        height: 70px;
        background-size: 100%;
    }
    .child-icon3::before {
        top: -33px;
        /* left: 30%; */
        width: 70px;
        height: 70px;
        background-size: 100%;
    }
    .child-icon2::before {
        top: -33px;
        /* left: 30%; */
        width: 70px;
        height: 70px;
        background-size: 100%;
    }

    .progress {
        margin-top: 0;
        height: 4px;
        margin-bottom: 30px;
    }

    .time {
        font-size: 16px;
    }

    .date {
        font-size: 13px;
    }

    .img-30 {
        width: 120px;
    }

    .sb-title_ftm {
        font-size: 18px;
    }

    .girl-sm_ftm {
        width: 140px;
    }
    
    .border-righ {
        padding-bottom: 45px;
    }
    .border-righ::after {
        right: 50%;
       bottom: 0;
    }
    .date-cirle {
        width: 180px;
        height: 180px;
    }
    .date-cirle h2 {
        font-size: 65px;
    }
    .date-cirle::after {
        width: 250px;
        height: 250px;
        top: -30px;
        left: -50px;
    }

}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 650px) {
    .form-card .max-60::before {
        display: none;
    }
    #login-sec_ftm::after {
        width: 100%;
        z-index: -2;
        height: 230px;
    }
    .border-righ {
        padding-bottom: 45px;
    }
    .border-righ::after {
        right: 50%;
       bottom: 0;
    }

    #banner::after {
        width: 100%;
        height: 20%;
        top: -20px;
        left: -82px;
    }

    .ftm_container img.img-fluid.logo {
        width: 100px;

    }

    .ftm_container header .custom-btn_ftm {
        font-size: 14px;
        padding: 15px 30px;
    }

    .custom-btn_ftm, .homeFBIner_ftm button {
        font-size: 16px !important;
        padding: 15px 30px !important;
    }

    .her-title {
        font-size: 34px;
    }

    .left-sec .desc {
        padding-right: 0;
        font-size: 16px;
    }

    .right-sec {
        padding-top: 30px;
    }

    .arrow::after {
        width: 65px;
        height: 84px;
    }

    #form-sec {
        margin-top: -70%;
    }

    #form-sec::after {
        height: 90px;
        top: -80px;
        left: 0;
        width: 320px;
    }

    #banner {
        padding-bottom: 370px;
    }

    .form-card {
        padding: 40px 30px 80px;
    }

    .timeline-sec {
        padding: 20px 20px 50px;
    }

    .timeline-sec .timeline-title {
        font-size: 20px;
        line-height: 1.2;
    }

    .timeline-title {
        font-size: 20px;
    }

    .timeline-sec .desc,
    .disney .desc {
        font-size: 14px;

    }

    .mt-40 {
        margin-top: 15px;
    }

    .child-icon1::before {
        width: 40px;
    }
    .child-icon2::before {
        width: 40px;
    }
    .child-icon3::before {
        width: 40px;
    }

    .time {
        font-size: 12px;
        min-height: 35px;
    }

    .date {
        font-size: 10px;
        line-height: 1.4;
    }

    .calculator-img {
        width: 50px;
    }

    .title {
        font-size: 25px;
    }

    .desc {
        font-size: 16px;
    }

    .gap-50 {
        gap: 20px;
    }

    .ftm_container footer {
        padding: 60px 0 0;
    }

    .max-80 {
        max-width: 100%;
    }

    .ftm_container footer .desc {
        font-size: 16px;
    }

    .Copyright {
        font-size: 12px !important;
        padding-bottom: 20px !important;
    }

    .pb-20 {
        padding-bottom: 10px;
    }

    .pt-20 {
        padding-top: 10px;
    }

    .ftm_container label {
        font-size: 14px;
    }

    .ftm_container input.form-control {
        padding: 15px 20px;
        height: 50px;
    }

    .sm-title {
        font-size: 12px;
        line-height: 1.1;
    }

    .mt-60 {
        margin-top: 20px;
    }

    #slider {
        padding: 30px 0;
    }

    .card {
        border-radius: 15px;
        padding: 20px;
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .type {
        font-size: 14px;
        padding: 10px 20px;
    }

    .card-title {
        font-size: 20px;
    }

    .card-desc {
        font-size: 14px;

    }

    #logo-sec .title br {
        display: none;
    }

    #logo-sec ul {
        flex-wrap: wrap;
    }

    #logo-sec ul li {
        width: 26%;
    }

    #started-sec {
        margin: 50px 0;
    }

    #started-sec .started-card {
        background-image: none;

        background: linear-gradient(89.76deg, #35c4fe40 0%, #bce0682e 100%);
        box-shadow: 0px 3.42286px 60.54px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(98.4073px);
    }

    .form-card .max-60::after {
        width: 30px;
        height: 40px;
        bottom: -250%;
    }

    #slider::after {
        width: 25px;
        height: 40px;

    }

    #slider::before {
        width: 60px;
        right: 0%;
    }

    .login-card_ftm {
        padding: 50px 20px 120px;
        margin-top: 120px;
        margin-bottom: 50px;
        border: 1px solid #E5E5E5;
        box-shadow: 0px 3.42286px 60.54px rgb(0 0 0 / 5%);
        backdrop-filter: blur(98px);
        background-image: none;
        border-radius: 20px;
    }


    .family-img_ftm {
        width: 100%;
        max-width: 90px;
        /* padding-top: 5px; */
    }

    .gap-10 {
        gap: 5px;
    }

    .login-card_ftm .custom-btn_ftm,
    .homeFBIner_ftm button {
        max-width: 100% !important;
        padding: 16px;
    }

    .custom-btn_ftm.blue {
        padding: 12px;
    }

    .girl-sm_ftm {
        width: 100px;
    }

    .login-card_ftm ul li {
        padding-bottom: 13px;
    }

    .sb-title_ftm {
        font-size: 14px;
        line-height: 1.6;
    }

    .icon-facebook svg {
        width: 10px;
    }

    .login-card_ftm .desc {
        font-size: 14px;
        line-height: 1.3;
        padding-bottom: 30px;
    }

    .login-card_ftm::after {

        width: 30px;
    }
    .date-cirle {
        margin: 20px auto 40px;
        padding-top: 10px;
        border: 11px solid #F8F4F4;
    }
    .date-cirle span {
        font-size: 15px;
    }
    .date-cirle h2 {
        font-size: 58px;
    }
    .date-cirle::after {
        width: 230px;
        height: 230px;
        top: -30px;
        left: -42px;
    }
}

/* @media only screen and (max-width: 992px) and (min-width: 768px) {
  .login-card_ftm .custom-btn_ftm {
     max-width: 100%;
  }
} */

@media screen and (max-width: 992px){
    .login-card_ftm .custom-btn_ftm,
    .homeFBIner_ftm button {
       max-width: 92%;
  }
  .homeFB_ftm svg {
    left: 16% !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 651px) {
    .login-card_ftm {
        min-height: 620px;
    }
}
@media screen and (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, 
    .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, 
    .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto,
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, 
    .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4,
    .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, 
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, 
    .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
      padding: 0px;
   }
   .login-card_ftm {
      min-height: 652px;
  }
}
@media screen and (max-width: 767px){
    /* .AddsiteSidebar {
        padding: 0px 18px !important;
    } */
    section#login-sec_ftm .container, .navbar_ftm .container {
        width: 90% !important;
        max-width: 100% !important;
    }
    .homeFB_ftm svg {
        left: 27% !important;
    }
}
@media screen and (max-width: 667px){
    .homeFB_ftm svg {
        left: 24% !important;
    }
}
@media screen and (max-width: 568px) {
    .homeFB_ftm svg {
        left: 14% !important;
    }
}
@media screen and (max-width: 450px) {
    .homeFB_ftm svg {
        left: 15% !important;
    }
}
@media screen and (max-width: 340px) {
    .title {
        font-size: 19px !important;
    }
}
@media screen and (max-width: 375px){
 .homeFB_ftm svg {
    left: 10% !important;
 }
}
@media screen and (max-width: 468px) {
 .homeFBIner_ftm button {
    padding-left: 26px!important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 1200px){
    .title_ftm {
       font-size: 40px;
    }
   }
 @media screen and (max-width: 992px){
    .title_ftm {
       font-size: 38px;
    }
   }
@media screen and (max-width: 650px){
 .title_ftm {
    font-size: 25px;
 }
}
@media screen and (max-width: 592px){
    .homeFB_ftm svg {
        width: 10px !important;
     }
    }
@media screen and (max-width: 320px){
 .homeFBIner_ftm button {
    padding-left: 26px!important;
    padding-right: 8px !important;
    font-size: 13px !important;
 }
 .login-card_ftm .custom-btn_ftm {
    font-size: 13px !important;
 }
 .custom-btn_ftm {
    font-size: 13px;
 }
 .homeFB_ftm svg {
    left: 10% !important;
    width: 8px !important;
 }
 .title_ftm {
    font-size: 22px;
  }
}
@media screen and (max-width: 280px){
    .login-card_ftm {
        padding: 19px 5px 120px;
    }
}
@media only screen and (max-width: 1275px) and (min-width: 992px) {
 #login-sec_ftm:after, #login-sec_ftm:before {
    width: 65%;
 }
}