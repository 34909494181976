@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    /* overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth !important; */
}
.text-end_ftm.arrow_ftm {
    padding-left: 20px;
}
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    display: flex;
    flex-direction: column;
    /* font-weight: normal;
    font-style: normal;
    min-height: 100%;
    font-family: 'Poppins';
    overflow: hidden; */
}

/* .ftm_container img {
    max-width: 100%;
} */
.ftm_container a:focus,
.ftm_container input:focus,
.ftm_container textarea:focus,
.ftm_container button:focus {
    text-decoration: none;
    outline: none;
}
.btn_ftm.focus_ftm,
.btn_ftm:focus {
    box-shadow: none;
}
.ftm_container a:focus,
.ftm_container a:hover {
    text-decoration: none;


}
.type_ftm:hover {
    color: #FF9781;
}
.ftm_container i,
.ftm_container span,
.ftm_container a {
    display: inline-block;
}
.ftm_container h1,
.ftm_container h3,
.ftm_container h4,
/* .ftm_container h5, */
.ftm_container h6 {
    font-family: 'Poppins';
    margin: 0px;
    letter-spacing: .3px;
    line-height: 1.1;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/* .ftm_container .col-12{
    padding-right: 25px;
    padding-left: 25px;
} */
/* .container {
    width: 90% !important;
    max-width: 100% !important;
} */
.select {
    outline: none;
}
p {
    /* font-family: 'Open Sans', sans-serif; */
    /* font-weight: 400;
    line-height: 1.3;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-style: normal;
    font-display: swap; */
}
.btn_ftm,
.ftm_container li,
.ftm_container button,
.ftm_container span,
.ftm_container small,
.ftm_container input {
    font-family: 'Poppins';
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    list-style-type: none;

}
/* p {
    margin: 0 !important;
} */
.ftm_container dl,
.ftm_container ol,
.ftm_container ul {
    margin-bottom: 0;
}
.ftm_container ::selection {
    background: #fbbe36;
    color: #fff;
}
.ftm_container ::-webkit-scrollbar {
    width: 1px;
}
.ftm_container ::placeholder {
    color: #c9c9c9 !important;
}

/* Track */
.ftm_container ::-webkit-scrollbar-track {
    background: #f9f9f9;
}
/* Handle */

.ftm_container ::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 3px;
    opacity: .6;
}
.form-control_ftm:focus {
    box-shadow: none;
    border-color: #fbbe36 !important;
}
.wrapper_ftm {
    flex-grow: 1;
    min-height: 88vh;
    background-image: linear-gradient(#fff9ef, #fff1f100) !important;
}
.border-righ_ftm {
    position: relative;
    margin-right: 30px;
}
.border-righ_ftm::after{
    content: "OR";
}
.border-righ_ftm::after {
    content: "or";
    position: absolute;
    bottom: 13px;
    right: -48px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Baloo 2', cursive;
    text-align: center;
    color: #555353;
    background: #d1d1d169;
    border-radius: 50%;
    
    padding: 5px 10px;
}
.ftm_container hr {
    margin: 0;
}
.ftm_container a {
    text-decoration: none;
}

.custom-btn_ftm {
    background: linear-gradient(342.36deg, #F8227C 22.05%, #FE5B60 58.72%);
    border-radius: 60px !important;
    color: #fff !important;
    font-family: 'Baloo 2', cursive !important;
    font-weight: bold;
    font-size: 19px;
    line-height: 1;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    padding: 15px 18px;
    border: 1px solid;
    border-color: linear-gradient(342.36deg, #F8227C 22.05%, #FE5B60 58.72%);
    /* transition: .4s; */
    cursor: pointer;
    border: 1px solid !important;
}
.blank_ftm {
    background: transparent;
    color: #555353 !important;
    border: 1px solid #E2E2E2 !important;
}
.btn-black_ftm {
    background: #555353;
    border-color: #555353;
}
.btn-black_ftm:hover {
    color: #555353;
}
/* .custom-btn_ftm:hover {
    background-color: #fff!important;
    background: #fff!important;
    cursor: pointer;
    border: 1px solid #F8227C;
    color: #F8227C !important;
    cursor: pointer;
} */
.custom-btn_ftm:hover {
    background-color: #fe324b !important;
    background: #fe324b !important;
    cursor: pointer;
    border: 1px solid #fe324b;
    color: #ffffff !important;
    cursor: pointer;
}
/* .blank_ftm:hover {
    background: linear-gradient(342.36deg, #F8227C 22.05%, #FE5B60 58.72%) !important;
    color: #fff !important;
    border-color: #F8227C;
} */
.blank_ftm:hover {
    background: #ffffff !important;
    color: #fff !important;
    border-color: #F8227C;
    color: #F8227C !important;
    border: 1px solid #F8227C !important;
}
.navbar-nav_ftm {
    padding-top: 20px;
}
.modal-header_ftm {
    background: linear-gradient(180deg, #FFB37F 0%, #FF7B0D 100%);
    border-bottom: 0;
}
.navbar-nav_ftm .nav-link_ftm {
    font-family: Poppins;
    font-size: 17px;
    line-height: 1;
    text-transform: uppercase;
    color: #000000 !important;
    padding-left: 15px !important;
}
.navbar-nav_ftm .nav-link_ftm:hover {
    color: #FA7A1B !important;
}

/* -----------------------start css--------------------------- */
.hide_ftm {
    display: none;
}
.ftm_container header {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 16px;
    left: 0px;
}
.pt-0_ftm {
    padding-top: 0;
}
.rounded_ftm {
    border-radius: 10px !important;
}
.ftm_container nav {
    top: 0px;
}
.navbar-brand_ftm {
    max-width: 150px;
}

/* -----------------------common css--------------------------- */
.title_ftm {
    font-family: 'Baloo 2', cursive;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    color: #555353;
    margin-bottom: 0px;
}
.owl-theme_ftm .owl-nav_ftm {
    margin-top: 30px;
}

/* -----------------------start css--------------------------- */

#banner_ftm {
    position: relative;
    padding-top: 150px;
    background: linear-gradient(122.84deg, rgba(249, 236, 254, 0.272) 8.46%, rgba(255, 252, 245, 0.503) 53.66%, rgba(255, 251, 242, 0.8) 97.01%);
    backdrop-filter: blur(174px);
    border-radius: 0px 0px 70px 70px;
    padding-bottom: 400px;
}
#banner_ftm::after {
    content: "";
    background-image: url("https://first.time.mom/assets/img/graident-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
#banner_ftm::before {
    content: "";
    background-image: url("https://first.time.mom/assets/img/top-right.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 35%;
    height: 55%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
#form-sec_ftm::after {
    content: "";
    background-image: url("https://first.time.mom/assets/img/star-line.png");
    background-position: center;
    background-size: 100%;
    width: 500px;
    height: 140px;
    position: absolute;
    top: -120px;
    left: 0;
    z-index: 0;
}
#form-sec_ftm {
    position: relative;
}
.timeline-sec {
    padding: 20px 80px 80px;
}
.timeline-sec .desc,
.disney .desc {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: #555353;
    opacity: 0.5;
    padding-bottom: 50px;
}
.progress-bar {
    background-color: #FA3971;
}
.pt-15 {
    padding-top: 15px;

}
.pt-20 {
    padding-top: 20px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-80 {
    padding-bottom: 80px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-50 {
    padding-bottom: 50px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-40 {
    margin-top: 40px;
}
.arrow {
    position: relative;
}
.arrow::after {
    content: "";
    background-image: url("https://first.time.mom/assets/img/btn-arrow.png");
    background-position: center;
    background-size: 100%;
    width: 135px;
    height: 154px;
    position: absolute;
    background-repeat: no-repeat;
    right: -50%;
    top: 0;
}
.form-card .max-60 {
    position: relative;
}


/* -----------------------logo css--------------------------- */

.gap-50 {
    gap: 50px;
}
.max-90 {
    max-width: 95%;
    margin: 0 auto !important;
}

/* -----------------------footer css--------------------------- */
.gap-15 {
    gap: 15px;
}
.max-80 {
    max-width: 72%;
    margin: 0 auto;
}
.ft-icon path{
    fill: #555353;
}
.ft-icon {
    transition: 1s ease-in-out;
}
.ft-icon:hover svg path {
    fill: #F8227C;
}

/* login page */
/* #login-sec {
    position: relative;
} */
#login-sec_ftm::after {
    content: "";
    background-image: url("https://first.time.mom/assets/img/color-img.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;

}
#login-sec_ftm::before {
    content: "";
    background-image: url("https://first.time.mom/assets/img/line-login.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 680px;
    position: absolute;
    /* bottom: 0; */
    left: 0;

}
.login-card_ftm {
    margin-top: 150px;
    background-image: url("https://first.time.mom/assets/img/form-bg.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 30px;
    padding: 55px 22px;
    backdrop-filter: blur(98.4073px);
    position: relative;
    z-index: 2;
    margin-bottom: 32px;
}
.login-card_ftm ul li {
    padding-bottom: 20px;
}
.login-card_ftm .custom-btn_ftm {
    display: block;
    /* max-width: 45%; */
    margin: auto;
}
.login-card_ftm .gap-10{
    gap: 5px;
}
.family-img_ftm {
    width: 100%;
    max-width: 117px;
    /* padding-top: 5px; */
}
.sb-title_ftm {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    color: #555353;
    letter-spacing: 0px;
    margin-bottom: 0px;
}
.login-card_ftm .desc {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 50px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 0px;
}
.girl-sm_ftm {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
}
.blue {
    background: #108CFF !important;
}
.login-card_ftm::before {
    content: "";
    background-image: url("https://first.time.mom/assets/img/curve-line.png");
    background-position: center;
    /* background-size: 100%; */
    width: 50px;
    height: 60px;
    position: absolute;
    background-repeat: no-repeat;
    right: 10%;
    bottom: 0;
}
.login-card_ftm::after {
    content: "";
    background-image: url("https://first.time.mom/assets/img/star.svg");
    background-position: center;
    background-size: 100%;
    width: 30px;
    height: 40px;
    position: absolute;
    background-repeat: no-repeat;
    right: 10%;
    top: -13%;
    transform: rotate(16deg);
}
.custom-btn_ftm:hover svg path {
    fill: #F8227C;
}
.date-cirle {
    background: radial-gradient(44% 40.91% at 50% 50%, #199A83 0%, #4BC0AB 82.29%, #42B9A4 99.98%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 15px solid #F8F4F4;
    position: relative;
    padding-top: 20px;
}
.date-cirle::after{
    content: "";
    background-image: url("https://first.time.mom/assets/img/star-pattren.png");
    position: absolute;
    width: 300px;
    height: 300px;
    top: -40px;
    left: -60px;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
}
.date-cirle span{
    font-family: 'Baloo 2', cursive;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #fff;
}
.date-cirle h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 79px;
    line-height: 1;
    color: #fff;
    font-family: 'Baloo 2', cursive;
}
.AddsiteSidebar {
    position: initial;
}
body {
    background-image: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-color: transparent;
    font-family: 'OmnesRegular';
}
.homeFB_ftm span {
    display: block;
    width: 100%;
}
.homeFB_ftm button {
    width: 100%;
}
.homeFB_ftm {
    position: relative;
}
.homeFB_ftm svg {
    position: absolute;
}
.homeFB_ftm svg {
    position: absolute;
    left: 13%;
    top: 22%;
    width: 12px;
}
.homeFBIner_ftm:hover svg path{
    fill: #F8227C;
}
.homeFBIner_ftm:hover svg path{
    fill: #ffffff;
}
.homeFBIner_ftm {
    display: block;
    width: 100%;
    position: relative;
}
.homeFBIner_ftm button {
    min-height: 50px;
    padding-left: 52px !important;
}

.navbar_ftm {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.homeFBIner_ftm button {
    background: #108CFF !important;
    display: block;
    margin: auto;
    min-height: 50px;
    width: 100%;
    border-radius: 60px !important;
    color: #fff;
    font-family: 'Baloo 2', cursive !important;
    font-weight: bold;
    font-size: 19px;
    line-height: 1;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    padding: 15px 18px;
    border: 1px solid #108CFF !important;
    border-color: linear-gradient(342.36deg, #F8227C 22.05%, #FE5B60 58.72%);
    transition: .4s;
    cursor: pointer;
    width: 100% !important;
    padding-left: 45px;
    border: none;
}
/* .homeFBIner_ftm button:hover {
    background-color: #fff !important;
    background: #fff !important;
    color: #F8227C !important;
    cursor: pointer;
    border: 1px solid #F8227C !important;
} */
.homeFBIner_ftm button:hover {
    background-color: #0077e5 !important;
    background: #0077e5 !important;
    color: #ffffff !important;
    cursor: pointer;
    border: 1px solid #0077e5 !important;
}

.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
}
.navbar-brand {
    max-width: 150px;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar_ftm .col-12 {
    padding: 0px;
}