

/* 13-dec-2019 (CSS for main page animation)*/

.program-tile {
    width: 347px;
    max-width: 371px;
    min-width: 320px;
    /* height: 200px;
    margin: 4px; */
    cursor: pointer;
    /* display: inline-block; */
    display: block;
    margin: auto; 
    z-index: 9;
}

.program-page-container {
    /* visibility: hidden; */
}

.program-page {
    word-break: break-word;
    cursor: pointer;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    max-width: 371px;
    margin: auto;
    right: 0;
    overflow-y: scroll;
    z-index: 9;
}

.hero-1 {
    /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #F9EAE2 0%, #F7E2E1 100%) !important; */
    /* background: #DC3C84; */
}

.hero-2 {
    background: #972FF8;
}

.hero-3 {
    background: #7DD6FE;
}

.hero-4 {
    background: #DC3C84;
}

.program-tile .contests_dtlImges img {
    width: 100%;
}
.program-page .contests_dtlImges img {
    width: 100%;
}

.program-page-container {
    width: 371px;
    max-width: 371px;
    display: block;
    overflow: hidden;
}

span.close_btn {
    position: absolute;
    right: 10px;
    top: 10px;
}

.program-page::-webkit-scrollbar {
    display: none;
}

.program-page .row {
    padding: 0;
}
.common_main_space12{
    padding: 0px 12px;
}


/* .active.program-page .descp_freebies, .active.program-page .program_detail_logo, .active.program-page .ends_date, .active.program-page .hometwo_upr{
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       /* -moz-animation: fadein 2s; /* Firefox < 16 */
        /* -ms-animation: fadein 2s; /* Internet Explorer */
         /* -o-animation: fadein 2s; /* Opera < 12.1 */
            /* animation: fadein 2s; */ 
    /* } */ 


    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0,10px,0)
        }
    
        to {
            transform: translate3d(0,0,0);
            opacity: 1!important;
        }
    }
    
    @-webkit-keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0,10px,0)
        }
    
        to {
            transform: translate3d(0,0,0);
            opacity: 1!important;
        }
    }

    /* .active.program-page .ends_date, .active.program-page .hometwo_upr,.active.program-page .instant_upr2 {
        opacity: 0;
    } */

    .active.program-page .ends_date, .active.program-page .hometwo_upr {
        -webkit-animation: fadeInUp 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeInUp 0.5s; /* Firefox < 16 */
        -ms-animation: fadeInUp 0.5s; /* Internet Explorer */
         -o-animation: fadeInUp 0.5s; /* Opera < 12.1 */
            animation: fadeInUp 0.5s;
    }

    .active.program-page .instant_redem2 , .active.program-page .freebies_redem {
        -webkit-animation: fadeInUp 0.5s ; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeInUp 0.5s; /* Firefox < 16 */
         -ms-animation: fadeInUp 0.5s; /* Internet Explorer */
          -o-animation: fadeInUp 0.5s; /* Opera < 12.1 */
             animation: fadeInUp 0.5s;
    }

    .active.program-page .descp_freebies, .active.program-page .descp_all, .active.program-page .family_fun, .active.program-page .perks_all, .active.program-page .deal_redem, .active.program-page .descp_mois, .active.program-page .uploadrqest_redem, .active.program-page .dtl_details_sub {
        -webkit-animation: fadeInUp 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeInUp 0.5s; /* Firefox < 16 */
        -ms-animation: fadeInUp 0.5s; /* Internet Explorer */
         -o-animation: fadeInUp 0.5s; /* Opera < 12.1 */
            animation: fadeInUp 0.5s;
    }


    @keyframes changeLogoPosition {
        from {
            opacity: 0.5;
            bottom: 16px;
        }
    }
    
    @-webkit-keyframes changeLogoPosition {
        from {
            opacity: 0.5;
            bottom: 16px;
        }
    }


    .active.program-page .freebies_iner p{
        font-size: 16px;
    }

    .active.program-page .freebies_iner label {
        font-size: 25px;
    }

    /* .active.program-page .program_detail_logo{
        -webkit-animation: changeLogoPosition 1s;
        -moz-animation: changeLogoPosition 1s;
        -ms-animation: changeLogoPosition 1s;
        -o-animation: changeLogoPosition 1s; 
            animation: changeLogoPosition 1s;
    } */

    .program-page img.social_nw {
        width: 52px;
    }


    @media screen and (max-width: 414px) {
        .program-page-container {
            width: 100%!important;
        }

        .program-page {
            max-width: 100%!important;
            width: 100%;
        }

        .program-tile {
            max-width: 100%!important;
            width: 100%;
        }

    }


/*26-dec-2019 style css */

.story_back_button img {
    /* width: 12px!important; */
    width: 24px !important;
    height: 24px;
}

.story_label label {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 114.21%;
    text-align: center;
    color: #FFFFFF;
    font-family: 'OmnesSemiBold';
}


.family_fun button{
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 0.3s; /* Firefox < 16 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
     -o-animation: fadein 0.3s; /* Opera < 12.1 */
        animation: fadein 0.3s;
}


.active.program-page .cstm_ImgesCntnt, .active.program-page .instant_details2{
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 0.3s; /* Firefox < 16 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
     -o-animation: fadein 0.3s; /* Opera < 12.1 */
        animation: fadein 0.3s;
}

.fade_it_up{
    -webkit-animation: fadeInUp 0.4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 0.4s; /* Firefox < 16 */
     -ms-animation: fadeInUp 0.4s; /* Internet Explorer */
      -o-animation: fadeInUp 0.4s; /* Opera < 12.1 */
         animation: fadeInUp 0.4s;
}

.ht-200 {
    margin-top: 20px;
    height: 200px;
    background: #fff;
    border-radius: 20px;
    display: none;
}

canvas{
    z-index: 10;
}

.active.nav-link .nav_dot{
    animation: runanimation 0.5s;
    display: initial;
    -webkit-animation: runanimation 0.5s;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
}

@keyframes runanimation {
    0%   {transform: scaleX(5); border-radius: 0.5px;}
    100% {transform: scaleX(1); border-radius: 3px;}
}

@-webkit-keyframes runanimation {
    0%   {transform: scaleX(5); border-radius: 0.5px;}
    100% {transform: scaleX(1); border-radius: 3px;}
}

.program-page{
        z-index: 11;
}